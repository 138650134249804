import React from "react";
import NaVBar from "../components/HomeNav";
import { Footer } from "../components/HomeFooter";
import { BannerContainer } from "../components/menu/BannerContainer";
import { MenuSection } from "../components/menu/MenuSection";
import { ReservationSection } from "../components/landing-page/ReservationSection";
import { CategoryMenuSection } from "../components/menu/CategoryMenuSection";

const MenuPage = () => {
  return (
    <>
      <head>
        <title>
          Kingston Downtown's Premier Dine-In Restaurant & Bar | Diverse Menu
          for Business Meetings, Parties, Date night, Takeaway, and More
        </title>
        <meta
          name="description"
          content="Explore Mercury Restaurant’s diverse menu in downtown Kingston, featuring family-friendly dishes, sports-themed specials, and options for large parties. Enjoy an array of gourmet selections crafted to delight every palate. Perfect for any occasion, from casual family dinners to lively group gatherings."
        />
      </head>
      <div className="content bg-[#333333] text-white">
        <NaVBar />
        <BannerContainer />
        <MenuSection />
        <CategoryMenuSection title="Breakfast" />
        <ReservationSection />
        <Footer />
      </div>
    </>
  );
};

export default MenuPage;
