import "./App.css";
import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./styles.css";
import Homepage from "./pages/Homepage";
import MenuPage from "./pages/MenuPage";
import DrinksMenuPage from "./pages/DrinksMenuPage";
import GoogleAnalytics from "./components/GoogleAnalytics";
import PubGrubMenuPage from "./pages/PubGrubMenuPage";
import LunchDinner from "./pages/LunchDinner";
// import ComingSoon from "./pages/ComingSoon";

function App() {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag("config", process.env.NEXT_PUBLIC_MEASUREMENT_ID, {
        page_path: location.pathname,
      });
    }
  }, [location]);
  return (
    <div>
      <GoogleAnalytics measurementId="G-TQDGJQJ05G" />{" "}
      <Routes>
        <Route path="/" element={<Homepage />} />{" "}
        <Route path="/menu" element={<MenuPage />} />{" "}
        <Route path="/lunch-dinner" element={<LunchDinner />} />
        <Route path="/drinks-menu" element={<DrinksMenuPage />} />{" "}
        <Route path="/pub-grub-menu" element={<PubGrubMenuPage />} />{" "}
      </Routes>{" "}
    </div>
  );
}

export default App;
