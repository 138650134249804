// Drinks menu

export const cocktail = [
  {
    name: "Caesar",
    message: "It contains Vodka, Clamato, Hot sauce and worcestershire sauce",
    page: 9.99,
  },
  {
    name: "Margarita",
    message:
      "Most popular tequila-based cocktail with triple sec and lime juice",
    page: 9.99,
  },
  {
    name: "Old fashioned",
    message:
      "Simple & traditional drink made by muddling sugar with bitter and Bourbon whisky",
    page: 9.99,
  },
  {
    name: "Espresso martini",
    message:
      "Best known mixed alcoholic drink with Vodka, Kahlua and Eespresso",
    page: 10.99,
  },
  {
    name: "Peach bellini",
    message: "Fancy italian wine cocktail with prosecco & peach puree'",
    page: 10.99,
  },
  {
    name: "Straw-man pina coloda",
    message: "Hawaiian pina colada with strawberry rum and Mango puree'",
    page: 10.99,
  },
  {
    name: "Cosmopolitan",
    message:
      "IBA official lip-smacking sweet and sour cocktail with vodka, Cointreau, Cranberry and lime juice",
    page: 9.99,
  },
  {
    name: "Hakuna matata",
    message: "A mixture of coconut rum, Spiced rum, Pineapple and Banana Creme",
    page: 9.99,
  },
  {
    name: "Aperol spritz",
    message:
      "One of the classic italian wine-based drink with prosecco bitters and soda",
    page: 9.99,
  },
  {
    name: "Moscow mule",
    message:
      "Most refreshing thing to sip with vodka, lime juice and ginger beer",
    page: 9.99,
  },
  {
    name: "Paloma",
    message:
      "Mexican thirst-quenching combination of tequila, lime and grapefruit soda",
    page: 9.99,
  },
  {
    name: "Red sangria",
    message:
      "A spanish drink made of red wine, orange or lemon juice, soda and cherry brandy",
    page: 10.99,
  },
  {
    name: "White sangria",
    message: "Crisp white wine, fresh fruit and sherry",
    page: 10.99,
  },
];

export const coffeContent = [
  {
    name: "Espresso",
    message: "",
    page: 4.25,
  },
  {
    name: "Americano",
    message: "",
    page: 4.5,
  },
  {
    name: "Affogato",
    message: "",
    page: 5.75,
  },
  {
    name: "Cappuccino",
    message: "",
    page: 4.95,
  },
  {
    name: "Cafe Latte",
    message: "",
    page: 5.75,
  },
  {
    name: "Mocha",
    message: "",
    page: 5.95,
  },
  {
    name: "Macchiato",
    message: "",
    page: 3.95,
  },
  {
    name: "Hot chocolate",
    message: "",
    page: 4.25,
  },
  {
    name: "London fog",
    message: "",
    page: 5.25,
  },
  {
    name: "Coffee",
    message: "",
    page: 3.0,
  },
  {
    name: "Decaf coffee",
    message: "",
    page: 3.25,
  },
  {
    name: "Speciality tea",
    message: "",
    page: 3.0,
  },
  {
    name: "Pops",
    message: "",
    page: 3.5,
  },
];

export const beerContent = [
  {
    name: "Bud light lager",
    message: "",
    page: 8.75,
  },
  {
    name: "Stella artois euro lager",
    message: "",
    page: 10.99,
  },
  {
    name: "Mill st organic lager",
    message: "",
    page: 8.75,
  },
  {
    name: "Michelob ultra light lager",
    message: "",
    page: 8.75,
  },
  {
    name: "Labatt 50 ale",
    message: "",
    page: 8.75,
  },
  {
    name: "Moosehead lager",
    message: "",
    page: 8.75,
  },
  {
    name: "Moosehead small batch east coast ipa",
    message: "",
    page: 8.75,
  },
  {
    name: "James ready lager",
    message: "",
    page: 6.99,
  },
  {
    name: "Twisted tea",
    message: "",
    page: 8.75,
  },
  {
    name: "Mackinnon brother's crosscut ale",
    message: "",
    page: 8.75,
  },
  {
    name: "Mackinnon brother's 8 man epa",
    message: "",
    page: 8.75,
  },
];

export const ciderContent = [
  {
    name: "Corona lager (330 ml)",
    message: "",
    page: 7.75,
  },
  {
    name: "Modelo especial pilsner (355 ml)",
    message: "",
    page: 7.75,
  },
  {
    name: "Alexander keith's session ale (341 ml)",
    message: "",
    page: 6.75,
  },
  {
    name: "Budweiser lager (330 ml)",
    message: "",
    page: 7.75,
  },
  {
    name: "Heineken (330 ml)",
    message: "",
    page: 7.75,
  },
  {
    name: "Molson canadian (355 ml)",
    message: "",
    page: 6.75,
  },
];

export const spiritContent = [
  {
    name: "Habibi's sharjah",
    message:
      "Vanilla ice-cream shake with banana, Cashew nuts, Frozen milk and cocoa",
    page: 7.45,
  },
  {
    name: "Cr\u00E9m\u00E9 mangue",
    message: "Vanilla ice-cream shaked with mango pure and mango chunks",
    page: 7.45,
  },
];

export const smoothies = [
  {
    name: "Strawberry banana smoothie",
    message: "Classic healthy smoothie with strawberry banana yogurt",
    page: 7.45,
  },
  {
    name: "Mango pineapple smoothie",
    message: "Summer healthy smoothie with mango pineapple yogurt",
    page: 7.45,
  },
];

export const mocktails = [
  {
    name: "Virgin mojito",
    message:
      "Cool down your day with a refreshing drink of mint, lime juice and soda",
    page: 7.45,
  },
  {
    name: "Italian soda",
    message: "Mix of flavoured syrup with soda water and top up with cream",
    page: 5.75,
  },
];

export const winesContent = [
  {
    name: "NV prosecco spumante - nua - piedmont, italy",
    message: "",
    oz: 11,
    glass: "-",
    bottle: 45,
  },
  {
    name: "Rosé peninsula niagara VQA",
    message: "",
    oz: 11,
    glass: 31,
    bottle: 45,
  },
  {
    name: "Riesling-stoney ridge estate winery, niagra",
    message: "",
    oz: 9.5,
    glass: 27,
    bottle: 42,
  },
  {
    name: "Chenin blanc, franschhoek vineyards, south africa",
    message: "",
    oz: 10.5,
    glass: 31,
    bottle: 45,
  },
  {
    name: "Chardonnay-costal vines- california",
    message: "",
    oz: 12,
    glass: 34,
    bottle: 52,
  },
  {
    name: "Sauvignon blanc 'civis'-terranoble, casablanca valley, chile",
    message: "",
    oz: 12,
    glass: 35,
    bottle: 52,
  },
  {
    name: "Pinot grigio-terre gaie - veneto, italy",
    message: "",
    oz: 12,
    glass: 35,
    bottle: 53,
  },
  {
    name: "Merlot-peninsula ridge estates winery, niagara",
    message: "",
    oz: 9.5,
    glass: 27,
    bottle: 42,
  },
  {
    name: "Shiraz 'hillside' - tall poppy, victoria, australia",
    message: "",
    oz: 11.5,
    glass: 33,
    bottle: 49,
  },
  {
    name: "Chianti 'villa di monte'- grati, tuscany, italy",
    message: "",
    oz: 12,
    glass: 34,
    bottle: 50,
  },
  {
    name: "Cabernet sauvignon - coastal vines, california",
    message: "",
    oz: 12,
    glass: 35,
    bottle: 53,
  },
  {
    name: "Malbec-septima, mendoza, argentina",
    message: "",
    oz: 12,
    glass: 35,
    bottle: 53,
  },
];

// Food Menu's

export const breadContent = [
  {
    name: "Tender mix green -  Gf, Veg",
    message:
      "Ontario-grown spring mix, Shaved carrots, Cucumber, Bell peppers, Balsamic dressing",
    page: 15,
    additional: "Small - 10",
  },
  {
    name: "Signature house salad - Veg",
    message:
      "Quinoa, Shredded kale, Shaved brussels sprouts, Cranberry, Citrus dressing",
    page: 18,
  },
  {
    name: "caesar salad",
    message:
      "Crisp romaine, Parmesan, Garlic croutons, Bacon, Creamy caesar dressing. Add chicken - 9 or Shrimp - 12",
    page: 17,
    additional: "Small - 12",
  },
  {
    name: "Soup of the day",
    message: "Ask your server for today's selection.",
    page: 9,
  },
];

export const veggiesContent = [
  {
    name: "Broccoli Cheddar",
    message: "veg",
    page: 9,
  },
  {
    name: "Thai Chicken Soup",
    message: "chicken",
    page: 12,
  },
];

export const fishContent = [
  {
    name: "Sambal chicken skewers",
    message: "Sambal oelek, Sweet chilli",
    page: 16,
  },
  {
    name: "Togarashi sesame crusted tuna",
    message: "Soba noodles, Julienne vegetables, Asian vinaigrette",
    page: 29,
  },
  {
    name: "Avocado infused hummus - Veg",
    message: "Pistachio dukkah, Pomegranate, Pita bread",
    page: 16,
  },
  {
    name: "Tater bombs",
    message: "Potato, Bacon, Mint sauce, Spiced yougurt, Sev",
    page: 16,
  },
  {
    name: "Ricotta rendezvous - Veg",
    message:
      "Whipped ricotta cheese, Sourdough, Herb mixed cherry tomatoes, Bell pepper pesto",
    page: 17,
  },
  // {
  //   name: "Turkey delight poutine",
  //   message:
  //     "Braised turkey, Cranberry mayo, Fresh quebec cheese curd, Crispy kale",
  //   page: 18,
  // },
  {
    name: "Crispy wings",
    message:
      "1 lb of crispy chicken wings tossed in our signature maple sauce or your choice of honey garlic, BBQ, buffalo, or sweet chili sauce",
    page: 18,
  },
  {
    name: "Coco-licious shrimp - GF",
    message: "House sauce, Grated coconut, Curry leaves",
    page: 25,
  },
  {
    name: "Nachos",
    message:
      "Jalapeno, Bacon, Banana pepper, 3 cheese, Salsa, Sour cream. Add guac - 3, add chicken/beef - 6",
    page: 17,
  },
  // {
  //   name: "Mercury beef medley",
  //   message: "Crispy beef strips, Garam masala, Peruvian chilli, Bell pepper",
  //   page: 24,
  // },
];

export const goatContent = [
  {
    name: "Itaewon beef burger",
    message:
      "Pulled beef brisket, Gochujang mayo, Kimchi, Smoked cheddar, Side of house fries or salad",
    page: 20,
  },
  {
    name: "Falafel wrap - Veg",
    message:
      "Smashed falafel, Hummus, Pickled onion, Mint aioli, Side of house fries or salad",
    page: 16,
  },
  {
    name: "Tacos",
    message:
      "Beef or Chicken, tortilla, lettuce, 3 cheese blend, pickled onion",
    page: 17,
  },
  {
    name: "Roast Beef Sandwich",
    message:
      "Sliced Roast beef, smoked cheddar, lettuce, tomato, Sourdough, Choice of fries or salad",
    page: 17,
  },
];

export const meatContent = [
  {
    name: "Confit duck & waffle",
    message: "Crispy duck, Sunnyside egg, Mustard maple syrup",
    page: 34,
  },
  {
    name: "Fish n chips",
    message: "Seared haddock, Mushy peas, Tartar sauce and crisp chips",
    page: 19,
  },
  {
    name: "Mercury butter chicken",
    message:
      "Creamy tomato-cashew sauce, Basmati rice and naan",
    page: 26,
  },
  {
    name: "Chicken parmesan",
    message:
      "Breaded chicken breast served with house made tomato sauce and spaghetti",
    page: 26,
  },
  {
    name: "Flame - kissed Striploin",
    message:
      "Fire grilled AAA 8oz Striploin, Seasonal vegetable, Demi glaze. Add shrimps - 8, Choice of mashed potatoes/fries/caesar salad",
    page: 40,
  },
  // {
  //   name: "Seared salmon - GF",
  //   message: "Thai bean ragu, Seasonal vegetable",
  //   page: 34,
  // },
  {
    name: "Rustic lamb shank",
    message:
      "Slow-cooked lamb shank on a bed of mashed potatoes, Seasonal vegetables and jus",
    page: 35,
  },
  {
    name: "Korean bbq short ribs - GF",
    message: "Marinated beef short ribs, Rice, Kimchi, Sauteed vegetables",
    page: 29,
  },
  {
    name: "Moroccan harvest stew -  Gf, Veg",
    message: "Moroccan spiced vegetable stew, Coconut cream, Basmati rice",
    page: 19,
  },
  {
    name: "Seafood linguine",
    message: "Shrimp, Hokkaido Scallops, Mussels, House-made tomato sauce",
    page: 28,
  },
  {
    name: "Pasta primavera - Veg",
    message: "Penne, Seasonal vegetable, Ros\u00E9 sauce",
    page: 20,
  },
  {
    name: "Chicken Alfredo",
    message: "Fresh linguine pasta with chicken breast in creamy Alfredo sauce",
    page: 24,
  },
  {
    name: "Penne alla Vodka - Veg",
    message: "Penne tossed in a rich tomato sauce, cream and splash of vodka",
    page: 20,
  },
  {
    name: "Spaghetti Bolognese",
    message: "Fresh spaghetti with slow cooked meat sauce",
    page: 23,
  },
];

export const dessertContent = [
  {
    name: "Chocolate decadence - GF",
    message: "Coconut cream, Chocolate glaze",
    page: 11,
  },
  {
    name: "Caramel affogato - GF",
    message: "Vanilla ice cream, Espresso",
    page: 13,
  },
  // {
  //   name: "Mango panna cotta",
  //   message: "Silky smooth cream, Mango jelly",
  //   page: 14,
  // },
  {
    name: "New york cheese cake",
    message: "Raspberry syrup, Cream",
    page: 12,
  },
  {
    name: "Ice cream",
    message: "Ask your server for flavors",
    page: 9,
  },
];

export const breakfastContent = [
  {
    name: "Mercury Breakfast",
    message: "Two eggs any style with bacon, ham or sausage, home fries, toast, fresh fruits with tea or coffee",
    page: 13,
  },
  {
    name: "Eggs Benedict",
    message: "Two eggs poached with pea meal bacon on an English muffin, topped with hollandaise sauce, served with home fries",
    page: 14,
  },
  {
    name: "3 Cheese Omelette",
    message: "Served with home fries and toast",
    page: 14,
  },
  {
    name: "Ham & Cheese Omelette",
    message: "Served with home fries and toast",
    page: 14,
  },
  {
    name: "Vegetable Omelette",
    message: "Served with home fries and toast",
    page: 14,
  },
  {
    name: "Pancakes",
    message: "3pc pancake, whipped cream",
    page: 14,
  },
  {
    name: "Pancakes with Bacon or Sausage",
    message: "Served with bacon or sausage",
    page: 16,
  },
  {
    name: "Grilled Cheese Sandwich",
    message: "Sour dough, cheddar",
    page: 8,
  },
  {
    name: "Breakfast Sandwich",
    message: "Fried egg, cheese, classic burger bun",
    page: 10,
  },
  {
    name: "Belgian Waffles",
    message: "Whipped cream, mix berries, add a scoop of ice cream for $3",
    page: 15,
  },
  {
    name: "Avocado Toast",
    message: "Sour dough, pistachio dukkah, add poached egg for $3",
    page: 13,
  },
  {
    name: "Granola Bowl",
    message: "Greek yogurt, mix berries",
    page: 14,
  },
];


export const pubgrub = [
  {
    name: "Queens Inn Fat City Burger",
    message: "Pea meal bacon, Onion rings, Classic burger sauce",
    page: 17,
  },
  {
    name: "Grilled Chicken Sandwich",
    message: "Roasted red pepper mayo, Grilled chicken",
    page: 16,
  },
  {
    name: "BBQ Ribs & Wings",
    message: "",
    page: 25,
  },
  {
    name: "BBQ Ribs & Fries",
    message: "",
    page: 20,
  },
  {
    name: "Crispy Fried Calamari",
    message: "",
    page: 16,
  },
  {
    name: "Onion Rings",
    message: "",
    page: 10,
  },
  {
    name: "Poutine",
    message: "",
    page: 13,
  },
  {
    name: "Mozzarella Sticks",
    message: "",
    page: 12,
  },
  {
    name: "Pepperoni Flatbread",
    message: "Pepperoni, Onion, Bell pepper, Smoked tomato sauce",
    page: 16,
  },
  {
    name: "Grilled Chicken Flatbread",
    message: "Grilled chicken, Onion, Bell pepper, Parmesan sauce",
    page: 17,
  },
  {
    name: "Old Town Flatbread",
    message: "Ask your server for the chef special",
    page: 18,
  },
];
